import { InfoCard } from "@backstage/core-components";

import { Divider, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

export const HomePageInfoCard = styled(InfoCard)(({ theme }) => ({
  width: '32%',
  [theme.breakpoints.down('md')] : {
    width: '100%',
  },
  marginTop: '20px',
  height: 'fit-content'
}));

export const CardSubtitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontSize: '12px',
  paddingBottom: '12px',
}));

export const CardListItem = styled(Typography)({
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CardListItemName = styled('span')({
  margin: '0 10px',
  flex: '1 1 auto',
  textAlign: 'left',
  wordBreak: 'break-word',
});

export const CardListItemDate = styled('span')({
  fontWeight: 600,
  fontSize: '12px'
})

export const CardDivider = styled(Divider)({
  margin: '16px 0',
});
