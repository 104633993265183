import React from 'react';

import { ListItem, ListItemIcon, ListItemText, Switch, Tooltip } from '@mui/material';

import { FeatureFlag } from '@backstage/core-plugin-api';

type Props = {
  flag: FeatureFlag;
  enabled: boolean;
  toggleHandler: Function;
};

const getSecondaryText = (flag: FeatureFlag) => {
  if (flag.description) {
    return flag.description;
  }
  return flag.pluginId
    ? `Registered in ${flag.pluginId} plugin`
    : 'Registered in the application';
};

export const FlagItem = ({ flag, enabled, toggleHandler }: Props) => (
  <ListItem divider button onClick={() => toggleHandler(flag.name)}>
    <ListItemIcon>
      <Tooltip placement="top" arrow title={enabled ? 'Disable' : 'Enable'}>
        <Switch color="primary" checked={enabled} name={flag.name} />
      </Tooltip>
    </ListItemIcon>
    <ListItemText primary={flag.name} secondary={getSecondaryText(flag)} />
  </ListItem>
);
