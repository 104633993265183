import React from "react";

import {
  EntityLayout,
  EntityAboutCard,
  EntityHasSystemsCard
} from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";

import { Grid } from "@mui/material";

import { entityWarningContent } from "../content/EntityWarningContent";

export const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid container item xs={12} >
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityAboutCard/>
            </Grid>
            <Grid item xs={12}>
              <EntityHasSystemsCard/>
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityCatalogGraphCard height={400}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
