import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { NovoAccessApi } from './NovoAccessApi';

export class NovoAccessClient implements NovoAccessApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  public async requestAccess(request: any): Promise<any> {
    const urlSegment = `request-access`;

    const response = await this.get<any>(urlSegment, request);
    return response;
  }

  private async get<T>(path: string, request: any): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('novoaccess')}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: "post",
      body: JSON.stringify(request) ,
      headers: {"Content-Type": "application/json"}
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json() as Promise<T>;
  }
}
