import React, { PropsWithChildren } from 'react';

import { UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import { FeatureFlagged } from '@backstage/core-app-api';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import ExtensionIcon from '@mui/icons-material/Extension';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ViewListIcon from '@mui/icons-material/ViewList';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SettingsIcon from '@mui/icons-material/Settings';
import ExploreIcon from '@mui/icons-material/Explore';
import { styled } from '@mui/material/styles';

import DeveloperPortalWatermark from "../../assets/developer-portal__watermark--light.png";
import DeveloperPortalShortWatermark from "../../assets/developer-portal__short-watermark--light.png";

const SidebarLogoWrapper = styled('div')({
  height: 3 * sidebarConfig.logoHeight,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: -14,
  width: '100%',
});

const SidebarLogoLink = styled(Link)({
  textAlign: 'center',
  '& .linkOpen': {
    width: sidebarConfig.drawerWidthOpen,
  },
  '& .linkClosed': {
    width: sidebarConfig.drawerWidthClosed,
  },
});

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <SidebarLogoWrapper >
      <SidebarLogoLink to="/" underline="none" className={isOpen ? 'linkOpen' : 'linkClosed'} aria-label="Home">
        <img
          src={isOpen ? DeveloperPortalWatermark : DeveloperPortalShortWatermark}
          alt='Developer Portal watermark'
          height={isOpen ? '64px' : '52px'}
        />
      </SidebarLogoLink>
    </SidebarLogoWrapper >
  );
}


export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={() => <HomeIcon fontSize='small' />} to="/" text="Home" />
        <SidebarItem icon={() => <CategoryIcon fontSize='small' />} to="catalog" text="Catalog" />
        <SidebarItem icon={() => <ExtensionIcon fontSize='small' />} to="api-docs" text="APIs" />
        <SidebarDivider />
        <SidebarItem icon={() => <AppRegistrationIcon fontSize='small' />} to="catalog-import" text="Register Entity" />
        <SidebarItem icon={() => <CreateComponentIcon fontSize='small' />} to="create" text="Run Template" />
        <SidebarItem icon={() => <ViewListIcon fontSize='small' />} to="software-templates" text="Templates List" />
        <SidebarDivider />
        <SidebarItem icon={() => <LibraryBooks fontSize='small' />} to="docs" text="TechDocs" />
        <FeatureFlagged with="show-faq-page">
          <SidebarItem icon={() => <HelpOutlineIcon fontSize='small' />} to="faq" text="FAQ" />
        </FeatureFlagged>
        <FeatureFlagged with="show-discovery-plugin">
          <SidebarItem icon={() => <ExploreIcon fontSize='small' />} to="discovery-plugin" text="Discovery Page" />
        </FeatureFlagged>
        <FeatureFlagged with="show-shortcuts-plugin">
          <SidebarDivider />
          <Shortcuts allowExternalLinks />
        </FeatureFlagged>
        <SidebarDivider />
        {/* End global nav */}
        <SidebarScrollWrapper />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarItem icon={() => <SettingsIcon />} to="settings" text="Settings" />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
