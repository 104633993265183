import React from "react";

import { FeatureFlagged } from "@backstage/core-app-api";
import {
  EntityLayout,
  EntitySwitch
} from "@backstage/plugin-catalog";
import {
  EntityGroupProfileCard,
  EntityOwnershipCard,
  EntityMembersListCard
} from "@backstage/plugin-org";

import { Grid } from "@mui/material";
import { EntityGithubInsightsReadmeCard } from "@roadiehq/backstage-plugin-github-insights";
import {
  isGithubTeamPullRequestsAvailable,
  EntityGithubGroupPullRequestsCard
} from "@roadiehq/backstage-plugin-github-pull-requests";

import { entityWarningContent } from "../content/EntityWarningContent";

export const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid container item xs={12} >
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityGroupProfileCard/>
            </Grid>
            <Grid item xs={12}>
              <EntityMembersListCard showAggregateMembersToggle/>
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityOwnershipCard/>
            </Grid>
            <FeatureFlagged with='show-github-insights'>
              <Grid item xs={12}>
                <EntityGithubInsightsReadmeCard maxHeight={350} />
              </Grid>
            </FeatureFlagged>
            <FeatureFlagged with="github-pr">
              <EntitySwitch>
                <EntitySwitch.Case if={isGithubTeamPullRequestsAvailable}>
                  <Grid item md={6} xs={12}>
                    <EntityGithubGroupPullRequestsCard/>
                  </Grid>
                </EntitySwitch.Case>
              </EntitySwitch>
            </FeatureFlagged>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
