import {
    genPageTheme,
    createUnifiedTheme,
    createBaseThemeOptions,
    palettes,
} from '@backstage/theme';

import { novoDefaultTheme, novoFontColor, novoColorPalettes } from './novoDesignTokens';
import ApisRegularFont from '../../assets/fonts/Apis-Regular.ttf';

const commonHeaderTheme = genPageTheme({
  colors: [novoDefaultTheme.white],
  shape: 'none',
  options: {
    fontColor: novoDefaultTheme.brand
  },
});

const nnFont = {
  fontFamily: 'Apis-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Apis-Regular'),
    url(${ApisRegularFont}) format('truetype'),
  `,
};

export const novoLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      // some of the colors are not used in this theme (left as default), but are here for reference
      brand: novoDefaultTheme.brand,
      common: {
        black: novoDefaultTheme.black,
        white: novoDefaultTheme.white,
      },
      primary: {
        main: novoDefaultTheme.primary,
        light: novoDefaultTheme.primary,
        dark: novoDefaultTheme.primaryHover,
      },
      secondary: {
        main: novoDefaultTheme.secondary,
        light: novoDefaultTheme.fontSecondary,
        dark: novoDefaultTheme.secondaryHover,
      },
      background: {
        default: novoDefaultTheme.bgMedium,
        paper: novoDefaultTheme.bgLight
      },
      error: {
        main: novoDefaultTheme.alert,
      },
      warning: {
        main: novoDefaultTheme.warning,
      },
      navigation: {
        background: novoDefaultTheme.brand,
        indicator: novoColorPalettes.lightBlue300,
        color: novoFontColor.white,
        selectedColor: novoColorPalettes.lightBlue300,
          navItem: {
            hoverBackground: novoColorPalettes.fadedBlue700
          },
      },
      status: {
        ok: novoDefaultTheme.success,
        warning: novoDefaultTheme.warning,
        error: novoDefaultTheme.alert,
        running: novoDefaultTheme.brand,
        pending: novoDefaultTheme.brand,
        aborted: novoDefaultTheme.brand,
      },
      errorText: novoDefaultTheme.alert,
      errorBackground: novoDefaultTheme.alertContent,
      warningText: novoDefaultTheme.warning,
      warningBackground: novoDefaultTheme.warningContent,
      infoText: novoDefaultTheme.info,
      infoBackground: novoDefaultTheme.infoContent,
      banner: {
        info: novoDefaultTheme.info,
        error: novoDefaultTheme.alert,
        text: novoDefaultTheme.white,
        link: novoDefaultTheme.primary,
        warning: novoDefaultTheme.warning,
        //  closeButtonColor: string;
      },
      info: {
        main: novoDefaultTheme.info,
      },
      success: {
        main: novoDefaultTheme.success,
      },
      text: {
        primary: novoDefaultTheme.fontPrimary,
        secondary: novoDefaultTheme.fontSecondary,
        disabled: novoDefaultTheme.disabledText,
      },
      border: novoDefaultTheme.baseOutlineHeavy,
      textContrast: novoDefaultTheme.fontPrimary,
      textSubtle: novoDefaultTheme.fontSecondary,
      textVerySubtle: novoDefaultTheme.fontSecondary,

      // highlight: string;
      // linkHover: string;
      // link: string;
      // gold: string;
      // pinSidebarButton: {
      //     icon: string;
      //     background: string;
      // },
      // tabbar: {
      //     indicator: string;
      // },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Apis-Regular, "Roboto", "Helvetica", "Arial", sans-serif !important',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [nnFont],
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          boxShadow: 'none !important',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
        },
      },
    },
  },

  /* below drives the header colors */
  pageTheme: {
    home: commonHeaderTheme,
    documentation: commonHeaderTheme,
    tool: commonHeaderTheme,
    service: commonHeaderTheme,
    website: commonHeaderTheme,
    library: commonHeaderTheme,
    other: commonHeaderTheme,
    app: commonHeaderTheme,
    apis: commonHeaderTheme,
  },
});
