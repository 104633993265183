import React, { useState, useEffect, useCallback } from "react";

import { githubAuthApiRef, useApi } from "@backstage/core-plugin-api";

import { Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import { Octokit } from '@octokit/rest';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { CardListItem, CardListItemDate, CardListItemName, CardSubtitle, HomePageInfoCard } from "../styled";
import { GithubNotAuthorized, useGithubLogin } from "../../../hooks/useGithubLogin";
import { GithubPullRequestOrIssue } from "../../../types";

export const CollaborateCard = () => {
  const [openPullRequests, setOpenPullRequests] = useState<GithubPullRequestOrIssue[]>([]);
  const [loading, setLoading] = useState(true);
  const githubApi = useApi(githubAuthApiRef);
  const isLoggedIn = useGithubLogin();
  const owner = 'innersource-nn';

  const fetchPullRequestsData = useCallback(async () => {
    try {
      setLoading(true);
      const token = await githubApi.getAccessToken(['repo']);
      const octokit = new Octokit({ auth: token });

      const searchResponse = await octokit.search.issuesAndPullRequests({
        per_page: 6,
        sort: 'created',
        q: `org:${owner}+is:open+type:pr`,
      });

      const fetchedPullRequests: GithubPullRequestOrIssue[] =
        searchResponse.data.items.map((pullRequest) => ({
          id: pullRequest.id,
          title: pullRequest.title,
          htmlUrl: pullRequest.html_url,
          createdAt: pullRequest.created_at,
          user: {
            login: pullRequest.user?.login || 'N/A',
          },
      }));

      setOpenPullRequests(fetchedPullRequests);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pull requests:", error);
      setLoading(false);
    }
  }, [githubApi, setOpenPullRequests, setLoading]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchPullRequestsData();
    }
  }, [isLoggedIn]);

  return (
    <HomePageInfoCard title="Collaborate in Inner-Source-NN">
      <CardContent>
      {isLoggedIn ? (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>recently created <b>pull requests</b> in all repositories</CardSubtitle>
          </Grid>
          {loading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openPullRequests.length ? (
                  openPullRequests.map((pullRequest) => (
                    <Grid item key={pullRequest.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(pullRequest.createdAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>"{pullRequest.title}" by: <b>{pullRequest.user.login}</b></CardListItemName>
                        <Button
                          component="a"
                          href={pullRequest.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{minWidth: 'fit-content'}}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem>No open pull requests found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      ) : (
        <GithubNotAuthorized />
      )}
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={`https://github.com/${owner}`}
            startIcon={<GitHubIcon />}
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </HomePageInfoCard>
  );
};
