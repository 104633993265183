import React, { useState, useEffect, useCallback } from "react";

import { githubAuthApiRef, useApi } from "@backstage/core-plugin-api";

import { Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import { Octokit } from '@octokit/rest';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { CardDivider, CardListItem, CardListItemDate, CardListItemName, CardSubtitle, HomePageInfoCard } from "../styled";
import { GithubNotAuthorized, useGithubLogin } from "../../../hooks/useGithubLogin";
import { GithubRelease } from "../../../types";

export const DiscoverCard = () => {
  const [releases, setReleases] = useState<GithubRelease[]>([]);
  const [loading, setLoading] = useState(true);
  const githubApi = useApi(githubAuthApiRef);
  const isLoggedIn = useGithubLogin();
  const owner = 'innersource-nn';
  const repo = 'midgard';

  const projects = [
    {
      id: 1,
      name: 'Bifrost',
      description: 'Telemetry data gateway',
      htmlUrl: '../catalog/default/system/bifrost'
    },
    {
      id: 2,
      name: 'Ymir',
      description: 'Control plane manifests',
      htmlUrl: '../catalog/default/system/ymir'
    },
    {
      id: 3,
      name: 'Design System',
      description: 'Digital design resource',
      htmlUrl: '../catalog/default/system/digital-design-system'
    }
  ]

  const fetchReleaseData = useCallback(async () => {
    try {
      setLoading(true);
      const token = await githubApi.getAccessToken(['repo']);
      const octokit = new Octokit({ auth: token });

      const response = await octokit.repos.listReleases({
        owner,
        repo,
        per_page: 3,
      });

      const fetchedReleases: GithubRelease[] = response.data.map((release) => ({
        id: release.id,
        name: release.name ?? 'N/A',
        tagName: release.tag_name,
        publishedAt: release.published_at ?? 'N/A',
        htmlUrl: release.html_url,
      }));

      setReleases(fetchedReleases);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching releases:", error);
      setLoading(false);
    }
  }, [githubApi, setReleases, setLoading]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchReleaseData();
    }
  }, [isLoggedIn]);

  return (
    <HomePageInfoCard title="Discover Developer Portal">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>trending projects from <b>catalog</b></CardSubtitle>
          </Grid>
          {projects.map((project) => (
            <Grid item key={project.id}>
              <CardListItem>
                <CardListItemName><b>{project.name}</b> - {project.description}</CardListItemName>
                <Button
                  component="a"
                  href={project.htmlUrl}
                  target="_blank"
                  rel="noopener"
                  style={{minWidth: 'fit-content'}}
                >
                  <OpenInNewIcon fontSize="small" />
                </Button>
              </CardListItem>
            </Grid>
          ))}
          <CardDivider />
          {isLoggedIn ? (
            <>
              <Grid item>
                <CardSubtitle>recent <b>releases</b> to production</CardSubtitle>
              </Grid>
              {loading ?
                (
                  <Box display="flex" justifyContent="center" width="100%" p={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {!!releases.length ? (
                      releases.map((release) => (
                        <Grid item key={release.id}>
                          <CardListItem>
                            <CardListItemDate>{new Date(release.publishedAt).toLocaleDateString()}</CardListItemDate>
                            <CardListItemName>{release.name}</CardListItemName>
                            <Button
                              component="a"
                              href={release.htmlUrl}
                              target="_blank"
                              rel="noopener"
                              style={{minWidth: 'fit-content'}}
                            >
                              <OpenInNewIcon fontSize="small" />
                            </Button>
                          </CardListItem>
                        </Grid>
                      ))
                    ) : (
                      <Grid item>
                        <CardListItem>No releases found</CardListItem>
                      </Grid>
                    )}
                  </>
                )
              }
            </>
          ) : (
            <GithubNotAuthorized />
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={`https://github.com/${owner}/midgard/issues`}
            startIcon={<GitHubIcon />}
          >
            <b>request feature or leave feedback</b>
          </Button>
        </Box>
      </CardActions>
    </HomePageInfoCard>
  );
};
