import {
    genPageTheme,
    createUnifiedTheme,
    createBaseThemeOptions,
    palettes,
} from '@backstage/theme';

import { novoColorPalettes, novoDefaultTheme, novoFontColor } from './novoDesignTokens';
import ApisRegularFont from '../../assets/fonts/Apis-Regular.ttf';

const commonHeaderTheme = genPageTheme({
  colors: [novoColorPalettes.sandGrey1000],
  shape: 'none',
  options: {
    fontColor: novoDefaultTheme.white
  },
});

const nnFont = {
  fontFamily: 'Apis-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Apis-Regular'),
    url(${ApisRegularFont}) format('truetype'),
  `,
};

export const novoDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      brand: novoDefaultTheme.brand,
      common: {
        black: novoDefaultTheme.black,
        white: novoDefaultTheme.white,
      },
      primary: {
        main: novoDefaultTheme.primaryMedium,
        light: novoDefaultTheme.primaryLight,
        dark: novoDefaultTheme.primaryLight,
      },
      secondary: {
        main: novoDefaultTheme.secondary,
        light: novoDefaultTheme.fontSecondary,
        dark: novoDefaultTheme.secondaryHover,
      },
      border: novoDefaultTheme.baseOutlineHeavy,
      navigation: {
        background: novoColorPalettes.fadedBlue1000,
        indicator: novoColorPalettes.lightBlue300,
        color: novoFontColor.white,
        selectedColor: novoColorPalettes.lightBlue300,
          navItem: {
            hoverBackground: novoColorPalettes.fadedBlue700
          },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Apis-Regular, "Roboto", "Helvetica", "Arial", sans-serif !important',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [nnFont],
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          boxShadow: 'none !important',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
        },
      },
    },
  },

  /* below drives the header colors */
  pageTheme: {
    home: commonHeaderTheme,
    documentation: commonHeaderTheme,
    tool: commonHeaderTheme,
    service: commonHeaderTheme,
    website: commonHeaderTheme,
    library: commonHeaderTheme,
    other: commonHeaderTheme,
    app: commonHeaderTheme,
    apis: commonHeaderTheme,
  },
});
