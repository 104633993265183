import React from "react";

import { FeatureFlagged } from "@backstage/core-app-api";
import {
  EntityProvidedApisCard,
  EntityConsumedApisCard } from "@backstage/plugin-api-docs";
import {
  EntityLayout,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard
} from "@backstage/plugin-catalog";

import { Grid } from "@mui/material";
import {
  isGithubPullRequestsAvailable,
  EntityGithubPullRequestsContent
} from "@roadiehq/backstage-plugin-github-pull-requests";

import { cicdContent } from "../content/CICDContent";
import { overviewContent } from "../content/OverviewContent";
import { techdocsContent } from "../content/TechDocsContent";

export const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityDependsOnComponentsCard/>
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityDependsOnResourcesCard/>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <FeatureFlagged with="github-pr">
      <EntityLayout.Route
        path="/pull-requests"
        title="Pull Requests"
        if={isGithubPullRequestsAvailable}
      >
        <EntityGithubPullRequestsContent />
      </EntityLayout.Route>
    </FeatureFlagged>
  </EntityLayout>
);
