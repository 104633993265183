import React from "react";

import { EntitySwitch } from "@backstage/plugin-catalog";
import { EntityNovoAccessCard } from "@internal/backstage-plugin-novoaccess/src/plugin";

import { Grid } from "@mui/material";
import { Entity } from "@backstage/catalog-model";

export const shouldDisplayNovoAccess = (entity : Entity) => !!(
  entity.metadata?.novoAccess
);

export const novoAccessEntityContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={shouldDisplayNovoAccess}>
      <Grid item xs={12}>
        <EntityNovoAccessCard />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch>
);
