import React, { useEffect, useState } from 'react';

import {
  useApi,
  githubAuthApiRef,
  SessionState,
} from '@backstage/core-plugin-api';

import { Grid, Tooltip, Button, Typography, styled } from '@material-ui/core';

const GithubTextInfo = styled(Typography)({
  fontSize: 'inherit',
});

export const GithubNotAuthorized = () => {
  const githubApi = useApi(githubAuthApiRef);
  return (
    <Grid container>
      <Grid item xs={8}>
        <GithubTextInfo>
          You are not currently logged in to your GitHub account. To view the content of this card, please sign in.
        </GithubTextInfo>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
        <Tooltip placement="top" arrow title="Sign in to Github">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => githubApi.getAccessToken('repo')}
          >
            Sign in
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const useGithubLogin = () => {
  const githubApi = useApi(githubAuthApiRef);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    githubApi.getAccessToken('repo', { optional: true });
    const authSubscription = githubApi.sessionState$().subscribe(state => {
      if (state === SessionState.SignedIn) {
        setIsLoggedIn(true);
      }
    });
    return () => {
      authSubscription.unsubscribe();
    };
  }, [githubApi]);

  return isLoggedIn;
};
