import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box, Collapse, ToggleButton } from '@mui/material';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { catalogApiRef, CATALOG_FILTER_EXISTS } from '@backstage/plugin-catalog-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { SearchType } from '@backstage/plugin-search';
import { SearchBar, SearchFilter, SearchResult, SearchPagination, useSearch } from '@backstage/plugin-search-react';
import { CatalogIcon, Content, DocsIcon, PageWithHeader } from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
    height: '40px',
    borderRadius: '20px',
    textTransform: 'none',
    fontSize: '0.875rem',
  },
  paginationContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const CustomSearchBar = styled(SearchBar)(({ theme }) => ({
  flexGrow: 1,
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    height: '40px',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 2),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SearchPage = () => {
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const orgName = configApi.getOptionalString('organization.name') ?? 'Backstage';

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const handleAdvancedFiltersChange = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  return (
    <PageWithHeader  title={`${orgName} Search`} themeId="home">
      <Content>
        <Box  className={classes.searchContainer}>
          <CustomSearchBar placeholder="Search" />
          <ToggleButton
            value="advancedFilters"
            selected={showAdvancedFilters}
            onChange={handleAdvancedFiltersChange}
            className={classes.toggleButton}
            color="primary"
          >
            Advanced
          </ToggleButton>
        </Box>

        <Collapse  in={showAdvancedFilters}>
          <StyledPaper>
            <Grid  container spacing={2}>
              <Grid  item xs={12} sm={4}>
                <SearchType.Accordion
                  name="Result Type"
                  types={[
                    { value: 'software-catalog', name: 'Software Catalog', icon: <CatalogIcon /> },
                    { value: 'techdocs', name: 'Documentation', icon: <DocsIcon /> },
                  ]}
                />
              </Grid>
              <Grid  item xs={12} sm={4}>
                <SearchFilter.Select label="Kind" name="kind" values={['Component', 'Template']} />
              </Grid>
              <Grid  item xs={12} sm={4}>
                <SearchFilter.Checkbox label="Lifecycle" name="lifecycle" values={['experimental', 'production']} />
              </Grid>
              {types.includes('techdocs') && (
                <Grid  item xs={12}>
                  <SearchFilter.Select
                    label="Entity"
                    name="name"
                    values={async () => {
                      const { items } = await catalogApi.getEntities({
                        fields: ['metadata.name'],
                        filter: { 'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS },
                      });
                      const names = items.map((entity) => entity.metadata.name);
                      names.sort();
                      return names;
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </StyledPaper>
        </Collapse>

        <StyledPaper>
          <Box  className={classes.paginationContainer}>
            <SearchPagination />
          </Box>
          <SearchResult>
            <CatalogSearchResultListItem icon={<CatalogIcon />} />
            <TechDocsSearchResultListItem icon={<DocsIcon />} />
          </SearchResult>
          <Box  className={classes.paginationContainer}>
            <SearchPagination />
          </Box>
        </StyledPaper>
      </Content>
    </PageWithHeader>
  );
};

export const searchPage = <SearchPage />;
