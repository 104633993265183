import {
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { discoveryInfoApiRef, DiscoveryInfoClient } from './api';

export const discoveryPluginPlugin = createPlugin({
  id: 'discovery-plugin',
  apis: [
    createApiFactory({
      api: discoveryInfoApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new DiscoveryInfoClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const DiscoveryPluginPage = discoveryPluginPlugin.provide(
  createRoutableExtension({
    name: 'DiscoveryPluginPage',
    component: () =>
      import('./components/DiscoveryPage').then(m => m.DiscoveryPage),
    mountPoint: rootRouteRef,
  }),
);
